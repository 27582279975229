<template>
  <div class="flex flex-wrap text-[10px] sm:text-xs justify-around">
    <CartPropertyLookUpValues
      v-if="allDefinitionsLookupValues?.length"
      :key="`cart-store-CartPropertyLookUpValues-${allDefinitionsLookupValues.length}`"
      :all-definitions-lookup-values="allDefinitionsLookupValues"
    />
    <div
      v-for="(addon, index) in addons"
      :key="`attribute-item-${index}`"
      class="m-1 text-center border border-text-200 rounded-md overflow-hidden px-2"
    >
      <div class="font-medium leading-1 text-mevcut-light">
        {{ addon.name }}
      </div>
      <div class="text-text_color px-2 py-0.5">
        {{ t('count') }} {{ addon.pivot?.quantity }}
      </div>
      <div class="font-light text-mevcut-light">
        {{ addon.price }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Addons, Definition } from '~/composables/useMenuModel'
const { t } = useI18n()
defineProps<{ addons?: Addons[], allDefinitionsLookupValues?: Definition[]}>()
</script>

<style>
.addons-paid-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}
</style>
