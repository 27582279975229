<template>
  <div
    class="text-text_color border border-text-200 rounded-lg my-1 py-1 relative text-xs sm:text-sm"
  >
    <div class="flex items-start">
      <div class="flex-shrink-0 relative overflow-hidden">
        <MevcutLink
          :to="useRouteMaterial(item.material, branchId)"
          :branch-id="branchId"
          :aria-label="t('aria_page_label', { page: item.material.name })"
        >
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="item.material.image"
            :alt="item.material.name"
            width="48"
            height="48"
            class="w-12 h-12 rounded-lg mx-2 object-cover object-center"
          />
        </MevcutLink>

        <!-- Discount Badge -->
        <div
          v-if="item.material.discountRate && !item.quantityByUnit"
          class="absolute top-5 ltr:-right-14 rtl:-left-14 bg-nav_color text-text_alt_color text-center whitespace-nowrap w-44 py-0.5 ltr:rotate-45 rtl:-rotate-45"
        >
          <!-- && item.material.store?.price_visible -->
          <small>
            {{
              t('discount_percentage', {
                value: parseInt(item.material.discountRate)
              })
            }}
          </small>
        </div>
      </div>

      <div class="flex-auto flex-shrink-1 px-2">
        <div class="text-mevcut-light font-medium">
          {{ item.material.name }}
        </div>
        <div class="flex items-center">
          <div class="text-mevcut-light text-center">
            <span v-if="item.hasOffer">
              {{ item.stringPrice }}
            </span>
            <span v-else>{{ item.stringOriginalPrice }}</span>
          </div>
          <div v-if="item.hasOffer" class="mx-3 text-mevcut-light text-center">
            <small class="line-through"> {{ item.stringOriginalPrice }}</small>
          </div>
        </div>
      </div>

      <div class="text-center mx-3">
        <div v-if="item.quantityByUnit">{{ item.quantityByUnit }}</div>
        <div v-else-if="item.hasOffer" class="flex justify-end">
          <div>{{ t('count') }}</div>
          :
          <div>{{ item.quantity }} {{ item.material.unit }}</div>
        </div>
      </div>
    </div>
    <MyAccountOrdersCardAddonsPaidAndLookUpValues
      :addons="item.addons"
      :all-definitions-lookup-values="item.allDefinitionsLookupValues"
    />
    <div v-if="item.stringfullPrice" class="flex justify-center font-medium mt-2">
      <div>{{ t('full_price') }}</div>:
      <div>{{ item.stringfullPrice }} </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BillDetail } from '~~/composables/useMenuModel'
import { useRouteMaterial } from '~/composables/useDomain'
defineProps<{ item: BillDetail; branchId?: number }>()

const { t } = useI18n()
</script>
