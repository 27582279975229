<template>
  <div v-if="order" class="min-h-[300px] mx-auto max-w-max">
    <div
      class="p-3 bg-bg_alt_color rounded-md text-lg mb-2 flex justify-center items-center"
    >
      <div class="font-semibold">{{ t('order_details') }}:</div>
      <div class="mx-1">{{ order.bill_number }}</div>
    </div>
    <div class="p-3 bg-bg_alt_color rounded-md">
      <MyAccountOrdersCardDetailsOrder :order="order" :expansion-bill-details="expansionBillDetails" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { UserOrder } from '~~/composables/useMenuModel'
const { billModel } = useMenuModel()
const { fetchBill } = useBill()
const { url } = useDomainHost()
const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    billId?: string
    withSeo?: boolean
    expansionBillDetails?: boolean
  }>(),
  {
    billId: undefined,
    withSeo: true,
    expansionBillDetails: false
  }
)

const order = ref<UserOrder | null>(null)

const { data: orderTemp, error } = await fetchBill(props.billId)

if (orderTemp.value) {
  order.value = billModel(orderTemp.value!)
}

useApiError(error.value)
if (props.withSeo) {
  useHead({
    title: t('bill') + order.value?.bill_number,
    link: [useSeoCanonical(url + '/my-account/orders' + order.value?.id)],
    script: [
      useSeoBreadcrumb([
        useSeoBreadcrumbItem(1, t('home'), url),
        useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
        useSeoBreadcrumbItem(3, t('orders'), url + '/my-account/orders'),
        useSeoBreadcrumbItem(
          4,
          t('bill') + order.value?.bill_number,
          url + '/my-account/orders' + order.value?.id
        )
      ])
    ]
  })
}
</script>
