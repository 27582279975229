<template>
  <div v-if="offerItems?.length" class="border border-text-200 rounded-lg my-2 px-1 pt-2 ">
    <div class="flex flex-wrap justify-between mb-3 text-xs sm:text-sm">
      <div class="mx-2 font-medium">
        {{
          t('offer_with', {
            number: offerItems[0].group_offer?.offer_number
          })
        }}
      </div>

      <div>
        <div class="font-medium">
          {{
            t('price_of_one_offer', {
              price: (': '+ offerItems[0].group_offer?.price)
            })
          }}
        </div>
      </div>
    </div>
    <div class="flex justify-center font-semibold text-xs sm:text-sm">
      <div>{{ t('full_price') }}</div>:
      <div>{{ fullOfferPrice }} </div>
    </div>

    <MyAccountOrdersCardMaterial
      v-for="billDetail in offerItems"
      :key="`offer-bill-item-${billDetail.id}`"
      :branch-id="branchId"
      :item="billDetail"
    />
  </div>
</template>

<script setup lang="ts">
import type { BillDetail } from '~/composables/useMenuModel'
const props = defineProps<{
  offerItems?: BillDetail[]
  offerNumber?: any
  branchId?: number
}>()

const { t } = useI18n()
const fullOfferPrice = computed(() => {
  const offerPrice = parseFloat(props.offerItems![0].group_offer?.price ?? '0')
  const addonsPrice = props.offerItems!.reduce((total, item) => {
    return total + (item.addonsPrice ?? 0)
  }, 0)
  return useStringPrice(
    offerPrice + addonsPrice,
    props.offerItems![0]!.group_offer?.currencyCode ?? ''
  )
})
</script>
