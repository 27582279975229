<template>
  <div
    v-if="
      order.stringState !== 'delivered' &&
        order.stringState !== 'rejected' &&
        order.stringState !== 'canceled'&&
        order.delivering_code
    "
    class="bg-bg_alt_color rounded-md mt-2"
  >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title>
          {{ t('view_delivering_code') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="p-2">
            <div>
              -
              {{
                t('note_delivering_code_1', {
                  delivering_code: order.delivering_code
                })
              }}
            </div>
            <div>
              -
              {{
                t('note_delivering_code_2', {
                  delivering_code: order.delivering_code
                })
              }}
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  order: UserOrder
}>()

const { t } = useI18n()
</script>
